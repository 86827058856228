<template>
  <section :class="`clients section ${getCurrentLangs.lang}`">
    <div class="clients__top">
      <Lottie
        :options="defaultOptions"
        class="clients__media desc"
        id="clients-animation"
        @play="startPlay"
        @animCreated="handleAnimation"
      />
      <div class="clients__top--content">
        <h2 class="section__title clients__title iosTitle type1">
          <span class="text-wrapper">
            <span class="letters">
              {{ `${$t('mainPage.clients.title[0]') + '&nbsp;'}` }}
            </span>
          </span>
          <span class="text-wrapper">
            <span class="letters">
              {{ $t('mainPage.clients.title[1]') }}
            </span>
          </span>
        </h2>
        <h4 class="section__subtitle clients__subtitle grow">{{ $t('mainPage.clients.subtitle') }}</h4>
        <p class="section__description clients__description grow">{{ $t('mainPage.clients.text[0]') }}</p>
        <p class="section__description clients__description grow">{{ $t('mainPage.clients.text[1]') }}</p>
        <p class="section__description clients__description grow">{{ $t('mainPage.clients.text[2]') }}</p>
      </div>
    </div>
    <ul class="clients__list grow">
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/MSD_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/MSD_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Pfizer_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/Pfizer_сolor.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/abbot_grey.png" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/abbot_colour.png" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Astra_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/Astra_color.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/sanofi_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/sanofi_color.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/gsk_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/gsk_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/bayer_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/bayer_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/takeda_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/takeda_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/teva_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/teva_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/boehringer_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/boehringer_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Reckitt_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/Reckitt_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/sandoz_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/sandoz.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/acino_grey.png" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/acino_colour.png" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/Reddys_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/Reddys_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/danone_grey.png" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/danone_colour.png" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/ipsen_grey.png" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/ipsen_colour.png" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/bionorica_grey.svg" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/bionorica_colour.svg" alt="">
      </li>
      <li class="clients__item">
        <img class="clients__item--img-grey" src="@/assets/img/main-page/clients/grindex_grey.png" alt="">
        <img class="clients__item--img-colour" src="@/assets/img/main-page/clients/grindex_colour.png" alt="">
      </li>
    </ul>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import Lottie from 'vue-lottie';
import animationData from '@/assets/style/animation/clients.json';
import getElementVisible from '@/helper';

export default {
  name: 'Clients',
  computed: {
    ...mapGetters([
      'getVisibleStage',
      'getCurrentLangs'
    ]),
  },
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: {
        animationData,
        loop: false,
        autoplay: false,
      },
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    play() {
      this.anim.play();
    },
    stop() {
      this.anim.stop();
    },
    startPlay() {
      setTimeout(() => {
        if (getElementVisible('clients-animation')) {
          this.play();
        }
      }, 0);
    },
  },
  mounted() {
    document.addEventListener('scroll', this.startPlay);
  },
};
</script>

<style lang="scss" scoped>
.clients {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 405px;

    &--content {
      width: 50%;
    }
  }

  &__title {
    min-width: 535px;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__media {
    &.desc {
      max-width: 500px;
    }
  }

  &__list {
    display: grid;
    // grid-template-columns: repeat(6, 189px);
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    padding: 0 390px;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid #92A1AE;
    border-top: 2px solid #92A1AE;
    height: 116px;
    position: relative;
    transition: all .3s ease;

    img {
      max-width: 53%;
      object-fit: contain;
      transition: opacity .3s ease;
    }

    .clients__item--img-colour {
      opacity: 0;
      position: absolute;
    }

    &:nth-of-type(1) {
      img {
        max-width: 53%;
      }
    }

    &:nth-of-type(3) {
      img {
        max-width: 60%;
      }
    }

    &:nth-of-type(4) {
      img {
        max-width: 80%;
      }
    }

    &:nth-of-type(6) {
      border-right: 2px solid #92A1AE;
    }

    &:nth-of-type(12) {
      border-right: 2px solid #92A1AE;

      img {
        max-width: 80%;
      }
    }

    &:nth-of-type(13) {
      img {
        max-width: 60%;
      }
    }

    &:nth-of-type(14) {
      img {
        max-width: 70%;
      }
    }

    &:nth-of-type(15) {
      img {
        max-height: 90%;
        max-width: 40%;
      }
    }

    &:nth-of-type(16) {
      img {
        max-width: 65%;
      }
    }

    &:nth-of-type(17) {
      img {
        max-width: 80%;
      }
    }

    &:nth-of-type(18) {
      border-right: 2px solid #92A1AE;

      img {
        max-width: 52%;
      }
    }

    // @media screen and (min-width: 1023px) {
      &:hover {
        background-color: var(--background-item-color-hover);

        .clients__item--img-grey {
          opacity: 0;
        }

        .clients__item--img-colour {
          opacity: 1;
        }
      }
  }

  @for $i from 13 through 18 {
    .clients__item:nth-of-type(#{$i}) {
      border-bottom: 2px solid #92A1AE;
    }
  }
}

@media screen and (max-width: 1600px) {
  .clients {
    &__top {
      padding-left: 172px;
      padding-right: 163px
    }

    &__list {
      padding-left: 163px;
      padding-right: 163px
    }
  }
}

@media screen and (max-width: 1440px) {
  .clients {
    &__list {
      margin-top: 28px;
    }

    &__item {
      height: 97px;
    }

    &__list {
      padding-left: 150px;
      padding-right: 150px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .clients {
    &__title {
      min-width: unset;
    }

    &__top {
      padding: 0;

      &--content {
        margin-right: 4%;
        min-width: 320px;
      }
    }

    &__media {
      width: 40%;
    }

    &__list {
      margin-top: 16px;
      grid-template-columns: repeat(6, 118px);
    }

    &__item {
      padding: 0 8px;
      height: 72px;
    }
  }
}

@media screen and (max-width: 960px) {
  .clients {
    &__title {
      .text-wrapper {
        padding-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .clients {
    padding: 0 44px;

    &__list {
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
    }
    &__item {
      border-left: 1px solid #92A1AE;
      border-top: 1px solid #92A1AE;

      &:nth-of-type(6),
      &:nth-of-type(12),
      &:nth-of-type(18) {
        border-right: 1px solid #92A1AE;
      }

      &:nth-of-type(1) {
        .clients__item--img-colour {
          max-width: 46%;
        }
      }

      &:nth-of-type(2) {
        .clients__item--img-colour {
          max-width: 46%;
        }
      }

      &:nth-of-type(3) {
        .clients__item--img-colour {
          max-width: 51.8%;
        }
      }

      &:nth-of-type(4) {
        .clients__item--img-colour {
          max-width: 69%;
        }
      }
      &:nth-of-type(5) {
        .clients__item--img-colour {
          max-width: 45.7%;
        }
      }
      &:nth-of-type(6) {
        .clients__item--img-colour {
          max-width: 45.59%;
        }
      }
      &:nth-of-type(7) {
        .clients__item--img-colour {
          max-width: 45.7%;
        }
      }
      &:nth-of-type(8) {
        .clients__item--img-colour {
          max-width: 45.7%;
        }
      }
      &:nth-of-type(9) {
        .clients__item--img-colour {
          max-width: 45.7%;
        }
      }
      &:nth-of-type(10) {
        .clients__item--img-colour {
          max-width: 45.7%;
        }
      }
      &:nth-of-type(11) {
        .clients__item--img-colour {
          max-width: 45.7%;
        }
      }

      &:nth-of-type(12) {
        .clients__item--img-colour {
          max-width: 68.8%;
        }
      }

      &:nth-of-type(13) {
        .clients__item--img-colour {
          max-width: 51.73%;
        }
      }

      &:nth-of-type(14) {
        .clients__item--img-colour {
          max-width: 60.35%;
        }
      }

      &:nth-of-type(15) {
        .clients__item--img-colour {
          max-width: 34.5%;
        }
      }

      &:nth-of-type(16) {
        .clients__item--img-colour {
          max-width: 56.04%;
        }
      }

      &:nth-of-type(17) {
        .clients__item--img-colour {
          max-width: 69%;
        }
      }

      &:nth-of-type(18) {
        .clients__item--img-colour {
          max-width: 44.7%;
        }
      }

      &:nth-of-type(13),
      &:nth-of-type(14),
      &:nth-of-type(15),
      &:nth-of-type(16),
      &:nth-of-type(17),
      &:nth-of-type(18) {
        border-bottom: 1px solid #92A1AE;
      }
    }
    @for $i from 13 through 18 {
      .clients__item:nth-of-type(#{$i}) {
        border-bottom: 1px solid #92A1AE;
      }
    }
  }
}

@media screen and (max-width: 705px) {
  .clients {
    &__media {
      &.desc {
        max-width: 70%;
      }
    }

    &__title {
      max-width: 50%;
    }

    &__top {
      flex-direction: column;

      &--content {
        width: 100%;
        margin-right: 0;
        min-width: unset;
      }
    }

    &__list {
      grid-template-columns: repeat(3, 1fr);
    }

    &__item {
      height: 64px;

      &:nth-of-type(6),
      &:nth-of-type(7) {
        img {
          max-width: 44%;
        }
        .clients__item--img-colour {
          max-width: 37.64%;
        }
      }

      &:nth-of-type(3),
      &:nth-of-type(9),
      &:nth-of-type(15) {
        border-right: 1px solid #92A1AE;
      }
    }

    @for $i from 12 through 15 {
      .clients__item:nth-of-type(#{$i}) {
        border-bottom: none;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .clients {
    padding: 0 20px;
  }
}

@media screen and (max-width: 425px) {
  .clients {
    &__title {
      max-width: 70%;
    }
    &__subtitle {
      margin-top: 24px;
    }
  }
}
@media screen and (max-width: 360px) {
  .clients {
    &__title {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 340px) {
  .clients {
    &__list {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}
</style>
